import { api } from '@/api/index';
import { IUnknownObject } from '@/@types/common';

export default {
    user: {
        auth: async (params: IUnknownObject) => await api.post('/user/login', params),
        check: async (params: IUnknownObject) => await api.post('/user/check', params),
        create: (params: IUnknownObject) => api.post('/user/create', params),
        getAll: () => api.get('/user/list'),
        update: (id: number, params: IUnknownObject) => api.put(`/user/${id}`, params),
        delete: (id: number) => api.delete(`/user/${id}`),
        resetPassword: (id: number, params: IUnknownObject) => api.put(`/user/change-password/${id}`, params),
        getRolesList: () => api.get(`/user/role`),
        updateRoleDescription: (id: number, params: IUnknownObject) => api.put(`/user/role/description/${id}`, params),
    },
    info: {
        get: async () => await api.get('/info'),
    },
    grid: {
        get: async () => await api.get('/depot/grid'),
    },
    move: {
        patch: async (params: IUnknownObject) => await api.patch('/move', params),
    },
    depot: {
        get: () => api.get('/depot'),
    },
    loco: {
        post: async (params: IUnknownObject) => await api.post('/loco', params),
    },
    sectionDate: {
        post: async (params: IUnknownObject) => await api.post('/sectionDate', params),
        patch: (sectionId: number, date: string) =>
            api.patch('/change-plans-to-leaveAt', { sectionId: sectionId, date: date }),
    },
    sectionJob: {
        get: (id: number) => api.get(`/section-job/info/${id}`),
        uploadWorkPlan: (id: number, formData: FormData) =>
            api.post(`section-job/work-plan/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }),
        delete: (id: number) => api.delete(`/section-job/${id}`),
        updateReadiness: (params: IUnknownObject) => api.put(`/section-job/readiness`, params),
        updateRepairCost: (params: IUnknownObject) => api.put(`/section-job/repair-cost`, params),
        updateContractNumber: (params: IUnknownObject) => api.put(`/section-job/contract-number`, params),
        deleteWorkPlan: (id: number) => api.delete(`section-job/work-plan/${id}`),
        updatePenaltyCost: (params: IUnknownObject) => api.put(`/section-job/penalty-cost`, params),
        getActualSectionJobsList: () => api.get('/section-job/list'),
        getRepairExitReportData: (params: IUnknownObject) => api.get('/section-job/repair-exit-report', { params }),
        getEndedSectionJobsList: () => api.get('/section-job/finished-section-jobs'),
        getCalculationReport: (id: number) => api.get(`/section-job/section-job-report/${id}`),
        getReleasedMaterials: (id: number, params = {}) => api.get(`/section-job/released-materials/${id}`, { params }),
        getSummaries: (id: number, params = {}) => api.get(`/section-job/summaries/${id}`, { params }),
        getCompleted: () => api.get(`/section-job/completed`),
    },
    sectionJobSummary: {
        get: (params: IUnknownObject) => api.get('/section-job-summary', { params }),
        create: (data: IUnknownObject) => api.post('/section-job-summary', data),
        update: (id: number, data: IUnknownObject) => api.put(`/section-job-summary/${id}`, data),
        delete: (id: number) => api.delete(`/section-job-summary/${id}`),
    },
    employeeTariff: {
        get: () => api.get('/employee-tariff'),
        getEmployeesWithoutTariff: () => api.get('/employee-tariff/tariff-needed'),
        create: (data: IUnknownObject) => api.post('/employee-tariff', data),
        update: (id: number, data: IUnknownObject) => api.put(`/employee-tariff/${id}`, data),
        delete: (id: number) => api.delete(`/employee-tariff/${id}`),
    },
    operationalAccounting: {
        getListOfSections: () => api.get('/section-job-summary/sections-with-summary'),
        getReport: (params: any) => api.get('/section-job-summary/report', { params }),
    },
    completeRepair: {
        patch: async (sectionId: any, date: any) =>
            await api.patch('/completeRepair', { sectionId: sectionId, date: date }),
    },
    leaving: {
        patch: async (sectionId: any, date: any, locoId: any) =>
            await api.patch('/leaving', { sectionId: sectionId, date: date, locoId: locoId }),
    },
    dailyReport: {
        post: async (date: any) => await api.post('/dailyReport', { date: date }),
    },
    employees: {
        get: async () => await api.get('/employees'),
        getAll: (params: IUnknownObject) => api.get('/employee', { params }),
        modal: async () => await api.get('/employees_modal'),
        post: async (params: IUnknownObject) => await api.post('/employee', params),
        patch: async (params: IUnknownObject) => await api.put('/employee', params),
        getReportCard: (params: IUnknownObject) => api.get('/employee/report-card', { params }),
        getReportCardPdf: (params: IUnknownObject) => api.get('/employee/report-card/pdf', { params, responseType: 'blob' }),
        getHeadCountReport: () => api.get('/employee/headcount'),
        getEmployeeSupplier: () => api.get('/employee/employee-supplier'),
        getEmployeesInSectionJobSummary: () => api.get('/employee/section-job-summary'),
    },
    employeeOrder: {
        getMany: () => api.get('/employee-order'),
        create: (params: IUnknownObject) => api.post('/employee-order', params),
        update: (id: number, params: IUnknownObject) => api.put(`/employee-order/${id}`, params),
        delete: (id: number) => api.delete(`/employee-order/${id}`),
    },
    tools: {
        get: async () => await api.get('/tools'),
        post: async (params: IUnknownObject) => await api.post('/tools', params),
        patch: async (params: IUnknownObject) => await api.patch('/tools', params),
    },
    organizations: {
        get: async () => await api.get('/organizations'),
    },
    toolsgroups: {
        get: async () => await api.get('/toolsgroups'),
    },
    changeStatus: {
        patch: async (params: IUnknownObject) => await api.patch('/statusedit', params),
    },
    toolsemployees: {
        get: async (params: IUnknownObject) => await api.get('/workshop-tool', { params }),
        post: async (params: IUnknownObject) => await api.post('/workshop-tool/issue', params),
        accept: async (id: number) => await api.get(`/workshop-tool/accept/${id}`),
        receipt: {
            get: async (from: any, to: any) => await api.get('/receiptTools?from=' + from + '&to=' + to),
            post: async (params: any) => await api.post('/receiptTools', params),
            patch: async (params: any) => await api.patch('/receiptTools', params),
            delete: async (id: any) => await api.delete('/receiptTools?id=' + id),
        },
    },
    repairreport: {
        get: async (params: IUnknownObject) => await api.post('/repairreport', params),
    },
    locomotives: {
        get: async () => await api.get('/locos'),
        getAll: () => api.get('/locomotive').then((r) => r.data),
        post: async (params: IUnknownObject) => await api.post('/loco', params),
        createSectionJob: async (params: IUnknownObject) => await api.post('/section-job/create', params),
    },
    alllocos: {
        get: async () => await api.get('/alllocos'),
    },
    materials: {
        get: async () => await api.get('/materials2'),
        getOld: () => api.get('/materials'),
        modal: async () => await api.get('/materials_modal'),
        show: async (id: any) => await api.get('/material/' + id),
        post: async (params: IUnknownObject) => await api.post('/materials2', params),
        patch: async (id: number, params: IUnknownObject) => await api.put(`/materials2/${id}`, params),
        delete: async (id: any) => await api.delete('/materials?id=' + id),
        merge: (params: IUnknownObject) => api.put('/materials2/merge', params),
        inventoryResidues: (params: IUnknownObject) => api.get('/materials2/inventory-residue', { params }),
    },
    materialStockTaking: {
        get: (params = {}) => api.get('/material-stocktaking', { params }),
        create: (params: IUnknownObject) => api.post('/material-stocktaking', params),
        update: (id: number, params: IUnknownObject) => api.put(`/material-stocktaking/${id}`, params),
        delete: (id: number) => api.delete(`/material-stocktaking/${id}`),
    },
    stock: {
        release: {
            get: async (from: any, to: any, locomotiveId?: any, materialId?: any, department?: string) =>
                await api.get('/materials2/released', {
                    params: {
                        ...(!!from && { from }),
                        ...(!!to && { to }),
                        ...(!!locomotiveId && { locomotiveId }),
                        ...(!!materialId && { materialId }),
                        ...(!!department && { department }),
                    },
                }),
            post: async (params: IUnknownObject) => await api.post('/releaseMaterial', params),
            patch: async (params: IUnknownObject) => await api.patch('/releaseMaterial', params),
            delete: async (id: any) => await api.delete('/releaseMaterial?id=' + id),
        },
        receipt: {
            get: async (params: any) => await api.get('/receiptMaterial', { params }),
        },
    },
    findNorm: {
        patch: async (params: IUnknownObject) => await api.patch('/findNorm', params),
    },
    matNorms: {
        get: async () => await api.get('/matNorms'),
    },
    documents: {
        setCredentials: async (params: IUnknownObject) => await api.post('/documents/set_credentials', params),
        checkAccess: async () => await api.get('/documents/check_access'),
    },
    locomotiveRepairs: {
        get: async (locoId: any) => await api.get('/locomotiveRepairs?locoId=' + locoId),
    },
    form2: {
        get: async () => await api.get('/form2'),
        post: async (params: any) => await api.post('/form2', params),
        delete: async (id: any) => await api.delete('/form2?id=' + id),
    },
    materialPrice: {
        post: async (params: any) => await api.post('/materialPrice', params),
        patch: async (params: any) => await api.patch('/materialPrice', params),
        delete: async (id: any) => await api.delete('/materialPrice?id=' + id),
    },
    powerCalculation: {
        get: async (year: any, categoryId: any) => await api.get('/plan/' + year + '?categoryId=' + categoryId),
    },
    plan: {
        post: async (params: any) => await api.post('/plan', params),
        delete: async (id: any) => await api.delete('/plan?id=' + id),
        patch: async (params: any) => await api.patch('/plan', params),
    },
    locoJobPriceDefault: {
        get: async () => await api.get('/locoJobPriceDefault'),
        patch: async (params: any) => await api.patch('/locoJobPriceDefault', params),
        getExceptions: async (id: any) => await api.get('/locoJobPriceExceptionGet/' + id),
    },
    exceptionsPlan: {
        get: async (id: any) => await api.get('/locoJobPriceExceptionGet/' + id),
        post: async (params: any) => await api.post('/addExceptionPlan', params),
        patch: async (params: any) => await api.patch('/editPlanExceptionContractor', params),
        delete: async (id: any) => await api.delete('/deleteExceptionPlan?id=' + id),
    },
    materialRequirement: {
        get: async (year: any, materialId: any, step: any) =>
            await api.get('/materialRequirement/' + year + '?materialId=' + materialId + '&step=' + step),
    },
    timeSheet: {
        get: async (date: any, masterId: any) => await api.get('/timesheet?date=' + date + '&masterId=' + masterId),
        getFull: async (date: any, masterId: any) =>
            await api.get('/timesheet?month=' + date + '&masterId=' + masterId),
        patch: async (params: IUnknownObject) => await api.patch('/timesheet', params),
        delete: async (employeeId: any, masterId: any) =>
            await api.delete('/timesheet?employeeId=' + employeeId + '&masterId=' + masterId),
    },
    dash: {
        get: async () => await api.get('/dash'),
    },
    sectionList: {
        get: async () => await api.get('/sectionList'),
    },
    supplier: {
        get: async (params?: IUnknownObject) => await api.get('/supplier', { params: params ? params : {} }),
        create: async (params: IUnknownObject) => await api.post('/supplier', params),
        update: async (params: IUnknownObject) => await api.patch('/supplier/', params),
    },
    jobCategories: {
        get: async () => await api.get('/jobCategories'),
    },
    locoModels: {
        get: async () => await api.get('/locoModels'),
    },
    newPlan: {
        get: async () => await api.get('/ditch-planning'),
        remove: async (id: number) => await api.delete(`/ditch-planning/${id}`),
        create: async (params: IUnknownObject) => await api.post('/ditch-planning', params),
    },
    rate: {
        get: async () => await api.get('/rate'),
        create: async (params: any) => await api.post('/rate', params),
        update: async (params: any) => await api.patch('/rate', params),
        remove: async (id: number) => await api.delete('/rate?id=' + id),
    },
    getrate: {
        get: async (locoModelId: number, jobCategoryId: number) =>
            await api.get('/getrate?locoModelId=' + locoModelId + '&jobCategoryId=' + jobCategoryId),
    },
    constructs: {
        get: async () => await api.get('/constructs'),
    },
    advancePayment: {
        get: async () => await api.get('/material-requirement'),
        getOne: (id: number) => api.get(`/material-requirement/${id}`),
        delete: (id: number) => api.delete(`/material-requirement/${id}`),
        create: async (params: IUnknownObject) => await api.post('/material-requirement', params),
        sendToApprove: (id: number) => api.get(`/material-requirement/to-approve/${id}`),
        sendToPurchase: async (id: number) => await api.put('/material-requirement/' + id),
        consolidatedReportOnMaterialMovement: () => api.get('/material-requirement/report/material-movement'),
        setLocomotive: (id: number, params: IUnknownObject) =>
            api.put(`/material-requirement/${id}/set-locomotive`, params),
    },
    apMaterial: {
        create: async (id: number, params: IUnknownObject) =>
            await api.post(`/material-requirement/${id}/material`, params),
        remove: async (id: number) => await api.delete('/material-requirement/material/' + id),
        update: (id: number, params: IUnknownObject) => api.put(`/material-requirement/material/${id}`, params),
        decline: (id: number, params: IUnknownObject) =>
            api.put(`/material-requirement/decline-material/${id}`, params),
    },
    apMaterials: {
        patch: async (params: any) => await api.put('/material-requirement/materials', params),
    },
    purchaseRequests: {
        get: async (params?: any) => await api.get('/purchase-request', { params }),
        setStatus: async (data: IUnknownObject[]) => await api.post('/supplierSelection', data),
        takeFromStock: (data: IUnknownObject) => api.post('/purchase-request/from-stock', data),
        bookingList: () => api.get('/purchase-request/from-stock'),
        downloadCommercialOffer: (params: IUnknownObject) =>
            api.post('/purchase-request/commercial-offer', params, {
                responseType: 'blob',
            }),
        reject: (data: IUnknownObject) => api.put('/purchase-request/reject', data),
    },
    invoices: {
        get: async (params: IUnknownObject) => await api.get('/invoices', { params }),
        getById: (id: number) => api.get('/invoices/' + id),
        create: async (params: IUnknownObject) => await api.post('/invoices', params),
        update: async (params: any) => await api.patch('/invoice', params),
        delete: async (id: number) => api.delete('/invoices/' + id),
        capitalize: (id: number) => api.post('/invoices/capitalize-invoice/' + id),
        createFromProtocol: (params: IUnknownObject) => api.post('/invoices/to-be-received', params),
    },
    invoiceStatus: {
        patch: async (id: number) => await api.patch('/invoiceStatus?id=' + id),
    },
    apmInfo: {
        get: async () => await api.get('/apmInfo'),
    },
    invoiceMaterials: {
        create: (params: unknown) => api.post('/invoices/material', params),
        delete: (id: number) => api.delete('/invoices/material/' + id),
        update: (params: unknown) => api.put('/invoices/material', params),
    },
    supplierSelection: {
        getAll: async () => await api.get('/supplierSelection'),
        getCountOfSentForApproval: () => api.get('/supplierSelection/sent-for-approval/count'),
        reject: (id: number) => api.put('/supplierSelection/reject/' + id),
        show: async (id: number) => await api.get('/supplierSelection/' + id),
        cancel: (id: number) => api.delete('/supplierSelection/' + id),
        update: async (params: any) => await api.patch('/supplierSelection', params),
        approve: async (id: number) => await api.patch('/supplierSelection/' + id),
        send: async (id: number) => await api.put('/supplierSelection/' + id),
        getToBeReceived: () => api.get('/supplierSelection/to-be-received'),
        updateSupplier: (params: IUnknownObject) => api.put('/supplierSelection', params),
        createAttachment: (formData: FormData) =>
            api.post(`/supplierSelection/attachment`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }),
        deleteAttachment: (attachmentId: number) => api.delete(`/supplierSelection/attachment/${attachmentId}`),
        setWinner: (params: IUnknownObject) => api.post(`/supplierSelection/winner`, params),
        setOnEdit: (id: number, params: IUnknownObject) => api.put('/supplierSelection/on-edit/' + id, params),
        cancelPurchaseRequest: (id: number) => api.get(`/purchase-request/remove-from-protocol/${id}`),
    },
    requestsPayments: {
        getAll: async (params?: IUnknownObject) => await api.get('/requestsPayments', { params }),
        pay: async (params: any) =>
            await api.post('/requestsPayments', params, { headers: { 'Content-Type': 'multipart/form-data' } }),
        sendPayments: async (params: any) => await api.patch('/requestsPayments', params),
        getWithCalendar: (params: IUnknownObject) => api.get('/supplierSelection/payment-info', { params }),
    },
    materialExcel: {
        post: async (params: any) => await api.post('/materialExcel', params, { responseType: 'arraybuffer' }),
    },
    department: {
        get: () => api.get('/department'),
        create: (params: IUnknownObject) => api.post('/department', params),
        update: (id: number, params: IUnknownObject) => api.put(`/department/${id}`, params),
        delete: (id: number) => api.delete(`/department/${id}`),
        getSubdivisions: () => api.get('/department/subdivision'),
        createSubdivision: (params: IUnknownObject) => api.post('/department/subdivision', params),
        updateSubdivision: (id: number, params: IUnknownObject) => api.put(`/department/subdivision/${id}`, params),
        deleteSubdivision: (id: number) => api.delete(`/department/subdivision/${id}`),
    },
    projectRegister: {
        create: (params: IUnknownObject) => api.post('/project-register', params),
        getMany: () => api.get('/project-register'),
        update: (id: number, params: IUnknownObject) => api.put(`/project-register/${id}`, params),
        calculate: (id: number, params: IUnknownObject) =>
            api.put(`/project-register/${id}/constructive-price`, params),
        getDatasheet: (id: number) => api.get(`/project-register/${id}/datasheet`),
        saveDatasheet: (id: number, params: IUnknownObject) => api.put(`/project-register/${id}/datasheet`, params),
        approve: (id: number, params: IUnknownObject) => api.put(`/project-register/${id}/approval`, params),
    },
    document: {
        getMany: (params: IUnknownObject) => api.get('/doc', { params }),
        create: (formData: FormData) =>
            api.post('/doc', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }),
        delete: (id: number) => api.delete(`/doc/${id}`),
        update: (id: number, params: IUnknownObject) => api.put(`/doc/${id}`, params),
        getTypes: () => api.get('/doc/type'),
        updateType: (id: number, params: IUnknownObject) => api.put(`/doc/type/${id}`, params),
        createType: (params: IUnknownObject) => api.post(`/doc/type`, params),
        deleteType: (id: number) => api.delete(`/doc/type/${id}`),
        getSubTypes: () => api.get('/doc/subtype'),
        updateSubType: (id: number, params: IUnknownObject) => api.put(`/doc/subtype/${id}`, params),
        createSubType: (params: IUnknownObject) => api.post(`/doc/subtype`, params),
        deleteSubType: (id: number) => api.delete(`/doc/subtype/${id}`),
    },
    notification: {
        get: () => api.get('/notification'),
    },
    auditLog: {
        get: () => api.get('/audit-log'),
    },
};
