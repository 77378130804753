import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { GlobalComponents } from "@/plugins/GlobalComponens";
import { Socket } from '@/composables/useSocket';

import './assets/scss/main.scss';

// @ts-ignore
import VueSimpleContextMenu from 'vue-simple-context-menu';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

import Tooltip from '@programic/vue3-tooltip';
import Notifications from '@kyvg/vue3-notification';

// Import the CSS or use your own!
import '@programic/vue3-tooltip/dist/index.css';

createApp(App)
    .use(GlobalComponents)
    .use(store)
    .use(VueSimpleContextMenu)
    .use(Tooltip, {
        position: 'bottom-center',
        type: 'light',
    })
    .use(router)
    .use(Notifications)
    .use(Socket)
    .mount('#app');
