<template>
    <div class="col-auto flex-grow-0 d-flex justify-content-between align-items-center">
        <select v-model="state.sectionJobId" class="form-select">
            <option disabled value="0">
                Выберите технику
            </option>
            <option v-for="sectionJob in state.endedSectionJobs" :key="sectionJob.id"
                :value="sectionJob.id">
                {{ sectionJob.name }}
            </option>
        </select>
    </div>
    <div class="mt-3">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-auto d-none d-sm-block flex-grow-1"></div>
                
            </div>
        </div>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 650px;">
                <CalculationReportTableComponent :constructives="state.constructives" :materials="state.materials" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>

import api from "@/api";
import { reactive, computed, watch } from "vue";
import formatMoney from "@/utils/formatMoney";
import { ISectionJob, constructiveReportType, materialType } from "@/@types/ISectionJob";
import CalculationReportTableComponent from './CalculationReportTableComponent.vue';

type reportStateType = {
    endedSectionJobs: ISectionJob[];
    sectionJobId: number;
    constructives: constructiveReportType[];
    materials: materialType[];
};
const state: reportStateType = reactive({
    endedSectionJobs: [],
    sectionJobId: 0,
    constructives: [],
    materials: [],
});

api.sectionJob.getEndedSectionJobsList().then(r => {
    state.endedSectionJobs = r.data;
});

watch(() => [state.sectionJobId], (v: any) => {
    if (!state.sectionJobId) {
        return;
    }
    api.sectionJob.getCalculationReport(state.sectionJobId).then(r => {
        state.constructives = r.data.constructives;
        state.materials = r.data.materials;
    })
})

const constructiveTotals = computed(() => {
    return state.constructives.reduce((acc, constructive) => {
        acc.hoursWorked += constructive.hoursWorked;
        acc.total += constructive.total;
        return acc;
    }, {
        hoursWorked: 0,
        total: 0,
    });
});

const materialTotals = computed(() => {
    return state.materials.reduce((acc, material) => {
        acc.count += Number(material.count);
        acc.total += Number(material.total);
        return acc;
    }, {
        count: 0,
        total: 0,
    });
});

</script>

<style scoped lang="scss"></style>
