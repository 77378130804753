<template>
    <div class="bg-white position-relative overflow-auto mw-100" style="max-height: 670px">
        <table class="table table-bordered mb-6 bg-white">
            <thead class="sticky-top bg-white">
                <tr class="position-relative border-0">
                    <th class="py-2 px-3">Дата</th>
                    <th class="py-2 px-3">Пользователь (логин)</th>
                    <th class="py-2 px-3">Модуль</th>
                    <th class="py-2 px-3">Операция</th>
                    <th class="py-2 px-3">id</th>
                    <th class="py-2 px-3">Описание</th>
                    <th class="py-2 px-3"></th>
                </tr>
            </thead>
            <tbody>
                <template v-if="state.logs.length">
                    <tr v-for="log in state.logs" :key="log.id">
                        <td class="py-2 px-3">
                            {{ formatDate(log.createdAt, 'DD.MM.YYYY HH:mm') }}
                        </td>
                        <td class="py-2 px-3">
                            {{ log.author?.login }}
                        </td>
                        <td class="py-2 px-3">
                            {{ log.tableName }}
                        </td>
                        <td class="py-2 px-3">
                            {{ log.operation }}
                        </td>
                        <td class="py-2 px-3">
                            {{ log.recordId }}
                        </td>
                        <td class="py-2 px-3">
                            {{ log.changes }}
                        </td>
                        <td class="py-2 px-3"></td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td class="py-5 text-center" colspan="7">Логов нет</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import formatDate from '@/utils/formatDate';
import api from '@/api';
import { IUser } from '@/@types/IUser';

type auditLogType = {
    id: number;
    tableName: string;
    operation: string;
    recordId: number;
    changes: string;
    userId: number;
    author: IUser;
    createdAt: string;
};

type stateType = {
    logs: Array<auditLogType>;
};

const state: stateType = reactive({
    logs: [],
});

function getAuditLogs() {
    return api.auditLog.get().then((res) => {
        state.logs = res.data;
    });
}

onMounted(() => {
    getAuditLogs();
});
</script>

<style scoped lang="scss"></style>
