<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Добавление накладной ТМЦ">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
	            <div class="col-12">
		            <form-group-ui label="Номер">
			            <input-ui v-model="state.number" />
		            </form-group-ui>
	            </div>
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Выберите дату"
			            label="Дата"
			            required>
			            <date-picker
				            v-model="state.date"
				            class="inline-block h-full"
				            :disabled-dates="dayList"
				            :is24hr="true"
				            mode="date">
				            <template #default="{ inputValue, togglePopover }">
					            <div class="flex items-center">
						            <input
							            class="form-control"
							            :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"as
							            readonly
							            :timezone="'Asia/Dhaka'"
							            :value="inputValue"
							            @blur="togglePopover()"
							            @focus="togglePopover()">
						            <div class="invalid-feedback">
							            Укажите дату
						            </div>
					            </div>
				            </template>
			            </date-picker>
		            </form-group-ui>
	            </div>
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Укажите поставщика"
			            label="Поставщик"
			            required>
			            <select-ui
				            :is-invalid="state.visibleErrors && isContractorError"
				            item-key="name"
				            :options="supplier"
				            placeholder=""
				            :searchable="true"
				            :values="[state.contractor]"
				            @on-selected="onSupplierSelected" />
		            </form-group-ui>
	            </div>
	            <div class="col-12">
		            <label class="form-check form-check-inline">
			            <input
				            v-model="state.vat"
				            class="form-check-input"
				            type="checkbox">
			            <span class="form-check-label">НДС</span>
		            </label>
	            </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Добавить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "InvoiceModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
	    const supplier = ref([]);

        const state = reactive({
	        id: 0,
	        number: '',
	        date: '',
	        contractor: 0,
            visibleErrors: false,
	        vat: false,
            loader: false,
        });

	    const isContractorError = computed(() => state.contractor === 0);
	    const isDateError = computed(() => state.date.length === 0);

        onMounted(() => {
            if (modal.data.hasOwnProperty('item')) {
                state.id = modal.data.item.id;
                state.contractor = modal.data.item.contractor;
				state.date = modal.data.item.date;
                state.number = modal.data.item.number;
                state.vat = modal.data.item.vat;
            }
	        api.supplier.get({ categoryType: 'supplier' }).then((i: any) => {
		        supplier.value = i.data;
	        });
        });

	    function onSupplierSelected(supplier: any) {
		    state.contractor = supplier.id;
	    }

        function send() {
            if (isContractorError.value || isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;

	            if (!modal.data.hasOwnProperty('item')) {
		            api.invoices.create({
			            number: state.number,
			            date: moment(state.date).format('YYYY-MM-DD'),
			            contractor: state.contractor,
			            isVatIncludedInPrice: !!state.vat,
		            }).then(r => {
			            modal.ok({
				            type: 'add',
			                item: r.data,
			            });
		            });
	            } else {
		            api.invoices.update({
			            id: state.id,
			            number: state.number,
			            date: moment(state.date).format('YYYY-MM-DD'),
			            contractor: state.contractor,
			            vat: state.vat,
		            }).then(r => {
			            modal.ok({
				            type: 'edit',
				            item: r.data,
			            });
		            });
	            }
            }
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
	        modal,
            cancel,
            close,
            state,
	        send,
	        isContractorError,
	        isDateError,
	        supplier,
	        onSupplierSelected
        };
    },
});
</script>

<style scoped lang="scss">

</style>
