<template>
    <div class="bg-white position-relative overflow-auto mw-100" style="max-height: 670px;">
        <table class="table table-bordered mb-6">
            <thead class="sticky-top bg-white">
                <tr class="position-relative border-0">
                    <th class="py-2 px-3">
                        Дата создания
                    </th>
                    <th class="py-2 px-3">
                        Организация/Юр.лицо
                    </th>
                    <th class="py-2 px-3">
                        <div class="d-flex align-items-center cursor-pointer" @click="props.onChangeSort('employeePlot')">
                            <div class="me-2">
                                Участок
                            </div>
                            <svg
                                height="16px"
                                style="enable-background:new 0 0 401.998 401.998;"
                                viewBox="0 0 401.998 401.998"
                                width="16px"
                                xml:space="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path
                                    d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                    c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                    c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"
                                    :fill-opacity="props.onFillOpacity('employeePlot', 'asc')" />
                                <path
                                    d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                    c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                    c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"
                                    :fill-opacity="props.onFillOpacity('employeePlot', 'desc')" />
                            </svg>
                        </div>
                    </th>
                    <th class="py-2 px-3">
                        Подразделение
                    </th>
                    <th class="py-2 px-3">
                        <div class="d-flex align-items-center cursor-pointer" @click="props.onChangeSort('employeeName')">
                            <div class="me-2">
                                ФИО
                            </div>
                            <svg
                                height="16px"
                                style="enable-background:new 0 0 401.998 401.998;"
                                viewBox="0 0 401.998 401.998"
                                width="16px"
                                xml:space="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path
                                    d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                    c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                    c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"
                                    :fill-opacity="props.onFillOpacity('employeeName', 'asc')" />
                                <path
                                    d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                    c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                    c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"
                                    :fill-opacity="props.onFillOpacity('employeeName', 'desc')" />
                            </svg>
                        </div>
                    </th>
                    <th class="py-2 px-3">
                        <div class="d-flex align-items-center cursor-pointer" @click="props.onChangeSort('employeePost')">
                            <div class="me-2">
                                Должность
                            </div>
                            <svg
                                height="16px"
                                style="enable-background:new 0 0 401.998 401.998;"
                                viewBox="0 0 401.998 401.998"
                                width="16px"
                                xml:space="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path
                                    d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                                    c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                                    c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"
                                    :fill-opacity="props.onFillOpacity('employeePost', 'asc')" />
                                <path
                                    d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                                    c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                                    c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"
                                    :fill-opacity="props.onFillOpacity('employeePost', 'desc')" />
                            </svg>
                        </div>
                    </th>
                    <th class="py-2 px-3">
                        <div class="d-flex align-items-center cursor-pointer">
                            <div class="me-2">
                                ИИН
                            </div>
                        </div>
                    </th>
                    <th class="py-2 px-3">
                        <div class="d-flex align-items-center cursor-pointer">
                            <div class="me-2">
                                Контакты
                            </div>
                        </div>
                    </th>
                    <th class="py-2 px-3">
                        СКУД
                    </th>
                    <th class="py-2 px-3" colspan="2">
                        Статус
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(employee) in props.employees" :key="employee.id">
                    <td class="py-2 px-3">
                        {{ formatDate(employee.createdAt) }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee.supplierName || employee.depoName }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee?.department?.subdivision?.name || employee.subdivision }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee?.department?.name || employee.employeePlot }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee.employeeName }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee.employeePost }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee.iin }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee.contact }}
                    </td>
                    <td class="py-2 px-3">
                        {{ employee.pacsCards }}
                    </td>
                    <td class="py-2 px-2" style="min-width: 120px;">
                        {{ STATUS_COLLECTION.find(s => s.VALUE === +employee.employeeStatus)?.LABEL || 'Неизвестный статус' }}
                        <div v-if="employee.hiredAt" class="mt-1">
                            Дата&nbsp;принятия:&nbsp;{{ formatDate(employee.hiredAt) }}
                        </div>
                        <div v-if="employee.firedAt" class="mt-1">
                            Дата&nbsp;увольнения:&nbsp;{{ formatDate(employee.firedAt) }}
                        </div>
                    </td>
                    <td class="py-2 px-3" style="width:50px">
                        <dropdown-ui v-if="employee.status !== 4">
                            <template #button>
                                <button class="btn btn-sm d-flex justify-content-center align-items-center">
                                    <vue-feather size="14px" type="more-vertical" />
                                </button>
                            </template>
                            <template #list>
                                <button-ui
                                    v-if="user.isAdmin || user.isKadry || user.isSuperAdmin"
                                    class="dropdown-item cursor-pointer"
                                    @click="props.onEdit(employee)">
                                    Редактировать
                                </button-ui>
                            </template>
                        </dropdown-ui>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { userStore } from "@/store/userStore";
import { IEmployee } from "@/@types/IEmployee";
import formatDate from '@/utils/formatDate';
import { STATUS_COLLECTION } from '@depo/constants/employee';

const props = defineProps({
    employees: {
        type: Array<IEmployee>,
        required: true,
    },
    statuses: {
        type: Array<Record<string, number | string>>,
        required: true,
    },
    onEdit: {
        type: Function as PropType<(employee: IEmployee) => void>,
        required: true,
    },
    onChangeSort: {
        type: Function as PropType<(arg1: string) => void>,
        required: true,
    },
    onFillOpacity: {
        type: Function as PropType<(col: string, by: string) => ('1.0' | '0.5')>,
        required: true,
    },
});

const { user } = userStore();
</script>

<style scoped lang="scss">

</style>
