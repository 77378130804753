<template>
    <modal-ui :id="id" size="modal-sm" title="Загрузка платежного поручения">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите файл" label="Выберите файл" required>
                        <input-ui
                            ref="file"
                            :is-invalid="isFileError && state.visibleErrors"
                            type="file"
                            @change="selectFile($event)" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui :invalid-feedback="amountErrorText" label="Сумма">
                        <input-ui
                            v-model="state.amount"
                            :is-invalid="state.visibleErrors && isAmountError"
                            :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                            maxlength="12"
                            placeholder=""
                            @input="handleAmountEnter" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui label="Валюта">
                        <input-ui v-model="state.currency" disabled />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Введите курс" label="Курс на дату оплаты">
                        <input-ui
                            v-model="state.exchangeRate"
                            :is-invalid="state.visibleErrors && isExchangeRateError"
                            :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                            maxlength="12"
                            placeholder=""
                            @input="handleExchangeEnter" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui label="Остаток">
                        <input-ui disabled :value="state.remainingAmountInNativeCurrency - +state.amount" />
                    </form-group-ui>
                </div>
                <div v-if="!isFullyPaid" class="col-12">
                    <form-group-ui invalid-feedback="Выберите дату" label="Дата следующего платежа" required>
                        <date-picker
                            v-model="state.nextDate"
                            class="inline-block h-full"
                            mode="date"
                            :timezone="'Asia/Almaty'">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{ 'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()" />
                                    <div class="invalid-feedback">Укажите дату следующей оплаты</div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui button-class="btn-primary"> Сохранить </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed } from 'vue';
import { DatePicker } from 'v-calendar';
import { CURRENCY } from '@depo/constants/purchase';
import { useModal } from '@/composables/useModal';
import api from '@/api';

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);

const state = reactive({
    id: 0,
    file: null,
    amount: '',
    nextDate: '',
    currency: CURRENCY.KZ_TENGE,
    exchangeRate: '1',
    remainingAmount: 0,
    remainingAmountInNativeCurrency: 0,
    totalInNativeCurrency: 0,
    visibleErrors: false,
    loader: false,
});

onMounted(() => {
    state.remainingAmount = modal.data.payment.remainingAmount;
    state.remainingAmountInNativeCurrency = modal.data.payment.remainingAmountInNativeCurrency;
    state.exchangeRate = modal.data.payment.exchangeRate;
    state.totalInNativeCurrency = modal.data.payment.totalInNativeCurrency;
    state.currency = modal.data.payment.currency;
});

const isFullyPaid = computed(() => Number(state.amount) >= state.remainingAmountInNativeCurrency);
const isFileError = computed(() => !state.file);
const isAmountError = computed(() => !+state.amount || Number(state.amount) > state.remainingAmountInNativeCurrency);
const isDateError = computed(() => (!isFullyPaid.value ? state.nextDate.length === 0 : false));
const amountErrorText = computed(() =>
    Number(state.amount) > state.remainingAmount ? 'Сумма превышает остаток' : 'Введите сумму',
);
const isExchangeRateError = computed(() => Number(state.exchangeRate) <= 0);

const isError = computed(() => isFileError.value || isAmountError.value || isDateError.value);

function selectFile(e: any) {
    let file = e.target.files[0];
    state.file = file;
}

function handleExchangeEnter() {
    state.exchangeRate = state.exchangeRate.replaceAll(',', '.');
}

function handleAmountEnter() {
    state.amount = state.amount.replaceAll(',', '.');
}

function send() {
    if (isError.value) {
        state.visibleErrors = true;
        return;
    }

    state.visibleErrors = false;
    state.loader = true;

    let fd = new FormData();
    // @ts-ignore
    fd.append('file', state.file);
    fd.append('id', modal.data.payment.paymentRegisterId);
    fd.append('amount', state.amount);
    fd.append('exchangeRate', state.exchangeRate);
    if (state.nextDate) {
        fd.append('nextDate', state.nextDate);
    }

    api.requestsPayments.pay(fd).then((r) => {
        modal.ok({
            item: r.data,
        });
    });
}
</script>

<style scoped lang="scss"></style>
