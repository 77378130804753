<template>
    <div class="bg-white position-relative overflow-auto mw-100" style="max-height: 670px">
        <table class="table table-bordered mb-6 bg-white">
            <thead class="sticky-top bg-white">
                <tr class="position-relative border-0">
                    <th class="py-2 px-3">Дата создания</th>
                    <th class="py-2 px-3">ФИО</th>
                    <th class="py-2 px-3">Тип приказа</th>
                    <th class="py-2 px-3">Период</th>
                    <th class="py-2 px-3">Примечание</th>
                    <th class="py-2 px-3"></th>
                </tr>
            </thead>
            <tbody>
                <template v-if="state.orders.length">
                    <tr v-for="employee in state.orders" :key="employee.id">
                        <td class="py-2 px-3">
                            {{ formatDate(employee.createdAt) }}
                        </td>
                        <td class="py-2 px-3">
                            {{ employee.employee?.name }}
                        </td>
                        <td class="py-2 px-3">
                            {{ employee.type }}
                        </td>
                        <td class="py-2 px-3">
                            {{
                                `${formatDate(employee.startsAt)}` +
                                `${employee.endsAt ? ` ${formatDate(employee.endsAt)}` : ''}`
                            }}
                        </td>
                        <td class="py-2 px-3">
                            {{ employee.comment }}
                        </td>
                        <td class="py-2 px-3" style="width: 50px">
                            <dropdown-ui>
                                <template #button>
                                    <button class="btn btn-sm d-flex justify-content-center align-items-center">
                                        <vue-feather size="14px" type="more-vertical" />
                                    </button>
                                </template>
                                <template #list>
                                    <button-ui
                                        v-if="user.isAdmin || user.isKadry || user.isSuperAdmin"
                                        class="dropdown-item cursor-pointer"
                                        @click="handleEditOrder(employee)">
                                        Редактировать
                                    </button-ui>
                                    <button-ui
                                        v-if="user.isAdmin || user.isKadry || user.isSuperAdmin"
                                        class="dropdown-item cursor-pointer"
                                        @click="handleDeleteOrder(employee)">
                                        Удалить
                                    </button-ui>
                                </template>
                            </dropdown-ui>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td class="py-5 text-center" :colspan="4">Приказы отсутствуют</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import { userStore } from '@/store/userStore';
import { useModal } from '@/composables/useModal';
import { IEmployeeOrder } from '@/@types/IEmployee';
import formatDate from '@/utils/formatDate';
import api from '@/api';

type stateType = {
    orders: Array<IEmployeeOrder>;
};

const { user } = userStore();
const state: stateType = reactive({
    orders: [],
});

function handleGetOrders() {
    return api.employeeOrder.getMany().then((response) => {
        state.orders = response.data;
    });
}

function handleCreateOrder() {
    useModal('employee_order_modal')
        .open({ type: 'add' })
        .then(handleGetOrders)
        .catch(console.error);
}

function handleEditOrder(employeeOrder: IEmployeeOrder) {
    useModal('employee_order_modal')
        .open({
            type: 'edit',
            employeeOrder,
        })
        .then(handleGetOrders)
        .catch(console.error);
}

function handleDeleteOrder(employeeOrder: IEmployeeOrder) {
    useModal('confirm_modal')
        .open({
            question: 'Подтвердите удаление приказа',
            ok: 'Да',
            cancel: 'Нет',
        })
        .then(() => api.employeeOrder.delete(employeeOrder.id))
        .then(handleGetOrders)
        .catch(console.error);
}

onMounted(handleGetOrders);
defineExpose({
    onCreateOrder: handleCreateOrder,
});
</script>

<style scoped lang="scss"></style>
