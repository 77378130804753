<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите дату" label="Дата" required>
                        <date-picker v-model="state.filledAt" as readonly :timezone="'Asia/Almaty'" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isFilledAtError && state.visibleErrors, 'bg-white': true }"
                                        :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <div class="row" v-for="(material, materialIdx) in state.materials" :key="materialIdx">
                        <div class="col-12 col-md-4">
                            <form-group-ui invalid-feedback="Укажите наименование ТМЦ" label="Наименование ТМЦ" required>
                                <select-ui :is-invalid="state.visibleErrors && isMaterialIdError(materialIdx)"
                                    :options="materials" placeholder="Наименование ТМЦ" :searchable="true"
                                    :values="[material.materialId]"
                                    @on-selected="(value: any) => onMaterialSelected(value, materialIdx)" />
                            </form-group-ui>
                        </div>
                        <div class="col-12 col-md-4">
                            <form-group-ui invalid-feedback="Укажите количество" label="Количество" required>
                                <input-ui v-model="material.remainsQuantity"
                                    :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }"
                                    :is-invalid="state.visibleErrors && isCountError(materialIdx)"
                                    @input="onCountEnter(materialIdx)"
                                    placeholder="Количество" />
                            </form-group-ui>
                        </div>
                        <div class="col-12 col-md-3">
                            <form-group-ui label="Примечание">
                                <input-ui v-model="material.comment" placeholder="Примечание" />
                            </form-group-ui>
                        </div>
                        <div v-if="state.materials.length > 1" class="col-auto flex-grow-0 flex-shrink-0">
                            <div class="mb-3">
                                <label class="form-label d-block" style="color: #fff">.</label>
                                <button-ui class="btn-outline-danger" type="button"
                                    @click="deleteMaterial(materialIdx)">
                                    x
                                </button-ui>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <button-ui class="btn-outline-secondary" type="button" @click="addMaterial">
                        Добавить материал
                    </button-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        Сохранить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts" async setup>
import { onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import { DatePicker } from 'v-calendar';
import api from "@/api";
import { IMaterial } from "@/@types/IMaterial";

const props = defineProps({
    id: Number,
});

const modal = useModal(String(props.id));

const materials = ref<IMaterial[]>([]);

const state = reactive({
    title: '',
    id: 0,
    materials: [
        {
            remainsQuantity: '',
            materialId: 0,
            comment: '',
        },
    ],
    filledAt: '',
    visibleErrors: false,
    loader: false,
});

const isMaterialIdError = computed(() => (idx: number) => !state.materials[idx].materialId);
const isCountError = computed(() => (idx: number) => +state.materials[idx].remainsQuantity <= 0);
const isFilledAtError = computed(() => !state.filledAt);

const errors = computed(() => {
    const isError = state.materials.some((m, idx) => isMaterialIdError.value(idx) || isCountError.value(idx));
    return isError || isFilledAtError.value;
});

onMounted(() => {
    state.title = 'Создание';

    api.materials.getOld().then(r => materials.value = r.data);
});

function send() {
    if (errors.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;
    api.materialStockTaking.create({
        materials: state.materials,
        filledAt: state.filledAt,
    }).then((r: any) => {
        if (r.data) {
            modal.ok(r.data);
        }
    }).finally(() => state.loader = false);
}

function addMaterial() {
    state.materials.push({
        remainsQuantity: '',
            materialId: 0,
            comment: '',
    });
}

function onMaterialSelected(_m: any, idx: number) {
    state.materials[idx].materialId = _m.id;
}

function onCountEnter(idx: number) {
    state.materials[idx].remainsQuantity = state.materials[idx].remainsQuantity.replaceAll(',', '.');
}

function deleteMaterial(idx: number) {
    state.materials.splice(idx, 1);
}

</script>

<style scoped lang="scss"></style>
