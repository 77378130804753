<template>
    <table class="table table-bordered" v-if="!!props.constructives.length || !!props.materials.length">
        <thead class="sticky-top bg-white">
            <tr class="position-relative border-0">
                <th class="py-2 px-3">№ п/п</th>
                <th class="py-2 px-3">Наименование</th>
                <th class="py-2 px-3" v-if="!props.isSectionJobPage">Ед изм</th>
                <th class="py-2 px-3" v-if="!props.isSectionJobPage">Количество</th>
                <th class="py-2 px-3">Сумма без НДС</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="props.constructives.length" class="bg-light">
                <td class="py-2 px-3">
                    <b>I</b>
                </td>
                <td class="py-2 px-3">Трудозатраты</td>
                <td class="py-2 px-3" v-if="!props.isSectionJobPage"></td>
                <td class="py-2 px-3 text-end" v-if="!props.isSectionJobPage">{{ constructiveTotals.hoursWorked }}</td>
                <td class="py-2 px-3 text-end">{{ formatMoney(constructiveTotals.total, 2, '.', '\xa0') }}</td>
            </tr>
            <template v-for="constructive in props.constructives" :key="constructive.name">
                <tr>
                    <td class="py-2 px-3 fw-bold">
                        {{ constructive.number }}.
                    </td>
                    <td class="py-2 px-3">{{ constructive.name }}</td>
                    <td class="py-2 px-3" v-if="!props.isSectionJobPage">Чел/час</td>
                    <td class="py-2 px-3 text-end" v-if="!props.isSectionJobPage">{{ constructive.hoursWorked }}</td>
                    <td class="py-2 px-3 text-end">
                        {{ constructive.total
                            ? formatMoney(+constructive.total, 2, '.', '\xa0')
                            : '0'
                        }}
                    </td>
                </tr>
            </template>
            <tr v-if="props.materials.length || props.isSectionJobPage" class="bg-light">
                <td class="py-2 px-3">
                    <b>II</b>
                </td>
                <td class="py-2 px-3">Материалы</td>
                <td class="py-2 px-3" v-if="!props.isSectionJobPage"></td>
                <td class="py-2 px-3 text-end" v-if="!props.isSectionJobPage"></td>
                <td class="py-2 px-3 text-end">{{ formatMoney(materialTotals.total, 2, '.', '\xa0') }}</td>
            </tr>
            <template v-if="!props.isSectionJobPage">
                <tr v-for="material in props.materials" :key="material.id">
                    <td class="py-2 px-3"></td>
                    <td class="py-2 px-3">{{ material.name }}</td>
                    <td class="py-2 px-3">{{ material.unit }}</td>
                    <td class="py-2 px-3 text-end">{{ material.count }}</td>
                    <td class="py-2 px-3 text-end">
                        {{ material.total
                            ? formatMoney(+material.total, 2, '.', '\xa0')
                            : '0'
                        }}
                    </td>
                </tr>
            </template>
            <template v-else>
                <tr v-for="constructive in props.constructives" :key="constructive.name">
                    <td class="py-2 px-3 fw-bold">
                        {{ constructive.number }}.
                    </td>
                    <td class="py-2 px-3">{{ constructive.name }}</td>
                    <td class="py-2 px-3" v-if="!props.isSectionJobPage">Чел/час</td>
                    <td class="py-2 px-3 text-end" v-if="!props.isSectionJobPage">{{ constructive.hoursWorked }}</td>
                    <td class="py-2 px-3 text-end">
                        {{ constructive.materialTotal
                            ? formatMoney(+constructive.materialTotal, 2, '.', '\xa0')
                            : '0'
                        }}
                    </td>
                </tr>
                <tr class="bg-light">
                    <td class="py-2 px-3"></td>
                    <td class="py-2 px-3">Итого</td>
                    <td class="py-2 px-3" v-if="!props.isSectionJobPage"></td>
                    <td class="py-2 px-3 text-end" v-if="!props.isSectionJobPage"></td>
                    <td class="py-2 px-3 text-end">
                        {{ formatMoney(materialTotals.total + constructiveTotals.total, 2, '.', '\xa0') }}
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script lang="ts" async setup>

import { computed } from "vue";
import formatMoney from "@/utils/formatMoney";
import type { constructiveReportType, materialType } from "@/@types/ISectionJob";

const props = defineProps({
    constructives: {
        type: Array<constructiveReportType>,
        required: true,
    },
    materials: {
        type: Array<materialType>,
        required: true,
    },
    isSectionJobPage: {
        type: Boolean,
        default: false,
    },
});

const constructiveTotals = computed(() => {
    return props.constructives.reduce((acc, constructive) => {
        acc.hoursWorked += constructive.hoursWorked;
        acc.total += constructive.total;
        return acc;
    }, {
        hoursWorked: 0,
        total: 0,
    });
});

const constructiveMaterialTotals = computed(() => {
    return props.constructives.reduce((acc, constructive) =>  acc += constructive.materialTotal, 0);
});

const materialTotals = computed(() => {
    return props.materials.reduce((acc, material) => {
        acc.count += Number(material.count);
        acc.total += Number(material.total);
        return acc;
    }, {
        count: 0,
        total: 0,
    });
});

</script>

<style scoped lang="scss"></style>
