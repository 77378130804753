<template>
    <div ref="target" class="dropdown d-inline-block position-relative">
        <div class="cursor-pointer" @click="state = !state">
            <slot name="button"></slot>
        </div>
        <div
            class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
            :class="{ 'show': state }"
            data-bs-popper="none">
            <slot name="list"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
    name: "DropdownUi",
    setup() {
	    const target = ref(null);
        const state = ref(false);

	    onClickOutside(target, (event) => {
		    // console.log(event);
		    state.value = false;
	    });

        function closeDropDown() {
            state.value = false;
        }

        function openDropDown() {
            state.value = true;
        }

	    return {
            target,
            state,
            closeDropDown,
            openDropDown,
        };
    },
});
</script>
